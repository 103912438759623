module.exports = [{
      plugin: require('/Users/patrickeddy/Documents/Dweebz/Code/thistle-and-thorn.github.io/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":690},
    },{
      plugin: require('/Users/patrickeddy/Documents/Dweebz/Code/thistle-and-thorn.github.io/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/patrickeddy/Documents/Dweebz/Code/thistle-and-thorn.github.io/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":""},
    },{
      plugin: require('/Users/patrickeddy/Documents/Dweebz/Code/thistle-and-thorn.github.io/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#c62828"},
    },{
      plugin: require('/Users/patrickeddy/Documents/Dweebz/Code/thistle-and-thorn.github.io/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/patrickeddy/Documents/Dweebz/Code/thistle-and-thorn.github.io/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/patrickeddy/Documents/Dweebz/Code/thistle-and-thorn.github.io/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
